import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook, faGithub, faTwitter, faSlack, faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

const Container = styled.div`
  display: flex;
  justify-content: ${props => (props.between ? 'space-between' : 'space-evenly')};
`;

export default function Sociallink({ white, between }) {
  return (
    <Container between={between}>
      <a href="https://github.com/tmrowco" aria-label="github link">
        <FontAwesomeIcon size="lg" color={white ? 'white' : 'black'} icon={faGithub} />
      </a>
      <a href="https://slack.tmrow.com" aria-label="slack link">
        <FontAwesomeIcon size="lg" color={white ? 'white' : 'black'} icon={faSlack} />
      </a>
      <a href="https://www.facebook.com/tmrowco" aria-label="facebook link">
        <FontAwesomeIcon size="lg" color={white ? 'white' : 'black'} icon={faFacebook} />
      </a>
      <a href="https://twitter.com/tmrowco" aria-label="twitter link">
        <FontAwesomeIcon size="lg" color={white ? 'white' : 'black'} icon={faTwitter} />
      </a>
      <a href="https://www.linkedin.com/company/tmrowco" aria-label="linkedin link">
        <FontAwesomeIcon size="lg" color={white ? 'white' : 'black'} icon={faLinkedin} />
      </a>
    </Container>
  );
}
