import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';

import theme from '../../utils/themeconstants';
import Mailchimp from '../elements/mailchimp';
import TmrowLogo from '../../images/svg/tomorrow_logo_dark_withsignet.svg';
import SocialLinks from '../elements/sociallinks';
import Link from '../elements/link';


const Container = styled.div`
  z-index: 999;
  padding: 6em; 
  background-color: ${theme.colors.bgSecondary};
  color: ${theme.colors.primary};
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
      padding: 4em 2em; 
    }
`;

const Content = styled.div`
  max-width: ${theme.dim.maxWidth};
  margin: 0 auto;
  display: flex;
  .column{
    align-content: flex-start;
    margin: 0;
    @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
      margin: 0 0 2em 0;
    }
  }
  .column:nth-child(1){
    flex-grow: 2;
  }
  .column:nth-child(2){
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .column:nth-child(3){
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .column:nth-child(4){
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .column:nth-child(5){
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
      justify-content: flex-start;
    }
  }
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
      flex-direction: column;
    }
`;

const List = styled.ul`
  list-style: none;
  font-weight: normal;
  margin: 0 3em 0 0;

  a.bold {
    font-weight: bold;
  }
`;

const Item = styled.li`
  a {
    text-decoration: none;
    h4{
      font-weight: normal;
    }
    &.bold h4 {
      font-weight: bold;
    }
  }
`;

const SocialContainer = styled.div`
  width: 66%;
  padding: 0 20px 0 0;
  margin: 0 0 2em 0;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
      width: 80%;
    }
`;

const EmailContainer = styled.div`
  width: 60%;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    width: 100%;
  }
`;

function Footer() {
  return (
    <Container>
      <Content>
        <div className="column">
          <TmrowLogo style={{ position: 'relative', top: '-12px' }} width={180} alt="tomorrow" />
          <SocialContainer>
            <SocialLinks between />
          </SocialContainer>
          <EmailContainer>
            subscribe to our Newsletter
            <Mailchimp
              color={theme.colors.primary}
              action="https://europe-west1-tmrowapp.cloudfunctions.net/mailchimp-proxy?u=8689fc68cd6c50ec671a95afb&amp;id=d11b2b98a9"
              fields={[
                {
                  name: 'EMAIL',
                  placeholder: 'Email',
                  type: 'email',
                  required: true,
                },
              ]}
              messages={{
                success: 'Thank you for joining the Newsletter!',
              }}
            />
          </EmailContainer>
        </div>
        <div className="column">
          <List>
            <Item>
              <Link to="/" className="bold"><h4><b>App</b></h4></Link>
            </Item>
            
          </List>
        </div>
        <div className="column">
          <List>
            <Item>
              <Link to="/mission" className="bold"><h4><b>About Us</b></h4></Link>
            </Item>
            <Item>
              <Link to="/tech"><h4>Tech</h4></Link>
            </Item>
            <Item>
              <Link to="/team"><h4>Team</h4></Link>
            </Item>
            <Item>
              <Link to="/jobs"><h4>Jobs <span style={{ fontWeight: 'bold', color: theme.colors.yellow }}>we&#39;re hiring</span></h4></Link>
            </Item>
            <Item>
              <Link to="/climatechange"><h4>Climate Change</h4></Link>
            </Item>
            <Item>
              <a style={{ color: theme.colors.primary }} href="https://electricitymap.org">
                <h4>
                  <b>electricityMap</b>
                  {' '}
                  <FontAwesomeIcon icon={faExternalLinkSquareAlt} size="xs" color={theme.colors.primary} />
                </h4>
              </a>
            </Item>
          </List>
        </div>
        <div className="column">
          <List>
            <Item>
              <Link to="/blog" className="bold"><h4><b>Blog</b></h4></Link>
            </Item>
          </List>
        </div>
        <div className="column">
          <div>
            <p>
              <b>Contact</b>
              <br />
              <Link linkcolor={theme.colors.primary} outward href="mailto:hello@tmrow.com">hello@tmrow.com</Link>
              <br />
              <br />
              <b>Copenhagen</b>
              <br />
              Njalsgade 19D
              <br />
              2300 Copenhagen
            </p>
          </div>
        </div>
      </Content>
    </Container>
  );
}

export default Footer;
