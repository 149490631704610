import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css'; // Import the CSS

config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  previewImageURL,
  url,
}) {
  return (
    <StaticQuery
      query={graphql`
      query DefaultSEOQuery {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `}
      render={(data) => {
        const metaDescription = description || data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: title || data.site.siteMetadata.title,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:image',
                name: 'image',
                content: previewImageURL || 'https://tmrow.com/tomorrow-social.png',
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                property: 'og:url',
                content: url || 'https://www.tmrow.com',
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image',
              },
              {
                name: 'twitter:creator',
                content: data.site.siteMetadata.author,
              },
              {
                name: 'twitter:title',
                content: title,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
              {
                name: 'twitter:image',
                content: previewImageURL || 'https://tmrow.com/tomorrow-social.png',
              },
              {
                name: 'twitter:site',
                content: '@tmrowco',
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                    name: 'keywords',
                    content: keywords.join(', '),
                  }
                  : [],
              )
              .concat(meta)}
          >
            <style type="text/css">
              {` 
                body {
                  -webkit-font-smoothing: antialiased;
                  background-color: #F6F9FC;
                }
              `}
            </style>
            <script type="application/ld+json">
              {JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'Organization',
                'url': 'https://www.tmrow.com',
                'logo': 'https://www.tmrow.com/tomorrow_logo_black_withsignet.png',
                'sameAs': [
                  'http://www.facebook.com/tmrowco',
                  'http://www.twitter.com/tmrowco',
                ],
              })}
            </script>
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
