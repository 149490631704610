import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

import theme from '../../utils/themeconstants';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => {
    if (props.mobilemenu) {
      return 'black';
    } if (props.inverted) {
      return 'white';
    } if (props.linkcolor) {
      return props.linkcolor;
    }
    return theme.colors.yellow;
  }};
  transition: opacity 0.4s;
  :hover {
    opacity: 0.8;
  }
`;

const StyledOutwardLink = styled.a`
  text-decoration: none;
  color: ${(props) => {
    if (props.mobilemenu) {
      return 'black';
    } if (props.inverted) {
      return 'white';
    } if (props.linkcolor) {
      return props.linkcolor;
    }
    return theme.colors.yellow;
  }};  transition: opacity 0.4s;
  :hover {
    opacity: 0.8;
  }
`;


export default function link({
  to, children, inverted, mobilemenu, linkcolor, outward, href,
}) {
  return (
    outward ? (
      <StyledOutwardLink
        href={href}
        inverted={inverted}
        linkcolor={linkcolor}
        mobilemenu={mobilemenu}
      >
        {children}
      </StyledOutwardLink>
    ) : (
      <StyledLink
        activeStyle={mobilemenu
          ? { color: theme.colors.red }
          : { color: linkcolor, fontWeight: 900 }}
        mobilemenu={mobilemenu}
        to={to}
        inverted={inverted}
        linkcolor={linkcolor}
      >
        {children}
      </StyledLink>
    )
  );
}

link.propTypes = {
  inverted: PropTypes.string,
};
