/* eslint-disable react/jsx-no-bind */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-useless-escape */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import jsonp from 'jsonp';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

// import theme from '../../utils/themeconstants';

const Form = styled.form`
  position: relative;
`;

const Button = styled.button`
  position: absolute;
  transform: translateX(-36px) translateY(24px);
  background: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const Input = styled.input`
  max-width: 100%;
  margin: 1em 0;
  background: transparent;
  border: 2px solid ${props => (props.color ? props.color : 'black')};
  padding: 6px 52px 6px 16px;
  border-radius: 100px;
  color: ${props => (props.color ? props.color : 'black')};
  font-size: bold;
  &:focus {
    outline: none;
  }
`;

const AlertMessage = styled.div`
  position: absolute; 
  width: 100%;
  p{
    background-color: transparent;
    color: ${props => (props.color ? props.color : 'black')};
    font-size: 12px;
    margin: 0 auto;
    text-align: center;
    padding: 2px 1em;
  }
`;


class Mailchimp extends React.Component {
  state = {};

  handleSubmit(evt) {
    evt.preventDefault();
    const { fields, action } = this.props;
    const values = fields.map(field => `${field.name}=${encodeURIComponent(this.state[field.name])}`).join('&');
    const path = `${action}&${values}`;
    const url = path.replace('/post?', '/post-json?');
    const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;
    const email = this.state['EMAIL'];
    (!regex.test(email)) ? this.setState({ status: 'empty' }) : this.sendData(url);
  }

  sendData(url) {
    this.setState({ status: 'sending' });
    jsonp(url, { param: 'c' }, (err, data) => {
      if (data.msg.includes('already subscribed')) {
        this.setState({ status: 'duplicate' });
      } else if (err) {
        this.setState({ status: 'error' });
      } else if (data.result !== 'success') {
        this.setState({ status: 'error' });
      } else {
        this.setState({ status: 'success' });
      }
    });
  }

  render() {
    const {
      fields, className, buttonClassName, messages, color,
    } = this.props;
    const mergedmessages = {
      ...Mailchimp.defaultProps.messages,
      ...messages,
    };
    const { status } = this.state;
    return (
      <Form onSubmit={this.handleSubmit.bind(this)} className={className}>
        {fields.map(input => (
          <Input
            {...input}
            key={input.name}
            onChange={({ target }) => this.setState({ [input.name]: target.value })}
            defaultValue={this.state[input.name]}
            color={color}
            aria-label="email input"
          />
        ))}
        <Button
          disabled={status === 'sending' || status === 'success'}
          type="submit"
          className={buttonClassName}
          aria-label="sign up for newsletter"
        >
          <FontAwesomeIcon color={color} icon={faChevronRight} />
        </Button>
        <AlertMessage color={color}>
          {status === 'sending' && <p>{mergedmessages.sending}</p>}
          {status === 'success' && <p>{mergedmessages.success}</p>}
          {status === 'duplicate' && <p>{mergedmessages.duplicate}</p>}
          {status === 'empty' && <p>{mergedmessages.empty}</p>}
          {status === 'error' && <p>{mergedmessages.error}</p>}
        </AlertMessage>
      </Form>
    );
  }
}

Mailchimp.defaultProps = {
  messages: {
    sending: 'Sending...',
    success: 'Thank you for joining the waitlist!',
    error: 'An unexpected internal error has occurred.',
    empty: 'You must write an e-mail.',
    duplicate: 'You are already a subscriber',
    button: 'Subscribe!',
  },
  buttonClassName: '',
};

Mailchimp.propTypes = {
  action: PropTypes.string,
  messages: PropTypes.object,
  fields: PropTypes.array,
  styles: PropTypes.object,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
};

export default Mailchimp;
